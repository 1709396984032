<template>
  <div>
    <!------------ START: Alert ------------>
    <div
      class="m-0"
      :class="[alertClass, field.link && 'alert-link']"
      @click="openLink"
    >
      <!------------ START: Alert icon ------------>
      <div v-if="field.icon" class="alert-icon">
        <i :class="[field.icon]" />
      </div>
      <!------------ END: Alert icon ------------>
      <!------------ START: Alert text ------------>
      <div class="alert-text">{{ getSnippet(field.label) }}</div>
      <!------------ END: Alert text ------------>
      <!------------ START: Alert count ------------>
      <div
        v-if="field.count !== undefined"
        class="alert-count font-weight-bolder"
      >
        {{ field.count }}
      </div>
      <!------------ END: Alert count ------------>
    </div>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: {},
  mixins: [base],
  props: {},
  data() {
    return {};
  },
  computed: {
    alertClass: function () {
      let alert = "alert alert-custom ";
      alert += "alert-" + (this.field.color ?? "primary");
      return alert;
    }
  },
  mounted() {},
  methods: {
    openLink() {
      if (!this.field.link) {
        return;
      }
      window.open(this.field.link, this.field.target ?? "_self");
    }
  }
};
</script>
